.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

@media screen and (max-width: 780px) {
  .container {
    position: relative;
    width: 240px;
    margin: 20px auto;
  }
}
.digi-facet-slider {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.digi-facet-slider__slot {
  position: relative;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
.digi-facet-slider__label {
  position: absolute;
  top: 53%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 12px;
  line-height: 15px;
  color: #aaa;
}
.digi-facet-slider__divider {
  -webkit-flex: 1 1 15px;
  flex: 1 1 15px;
  margin: 0 10px;
  height: 1px;
  background: #d5d5d5;
}
.digi-facet-slider__input {
  width: 100%;
  height: 36px;
  padding: 0 15px 0 26px;
  border: 1px solid #d5d5d5;
  color: #000000;
}
.digi-facet-slider__label-rub {
  right: 10px;
  left: auto;
}
.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__track_chhosen {
  position: absolute;
  top: 0;
  background: black;
  z-index: 2;
  height: 5px;
}
.btn_submit {
  width: 150px;
  height: 30px;
  border: none;
  background: black;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  cursor: pointer;
}
.btn_submit:hover {
  opacity: 0.5;
}
.inputs_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  width: 100%;
  position: absolute;
}
.input {
  display: flex;
  align-items: center;
  font-family: "HelveticaNeueCyr", sans-serif;
  font-size: 14px;
}
.input span {
  color: #000000;
  opacity: 0.4;
  margin: 0 5px 0 0;
}
.input input {
  width: 100%;
  max-width: 80px;
  padding: 1px 5px;
  font-size: 14px;
  height: 30px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.slider__range {
  background-color: #9fe5e1;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}

.thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  cursor: pointer;
  height: 18px;
  width: 18px;
  border: 2px solid black;
  border-radius: 50%;
  background-color: #fff;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  cursor: pointer;
  height: 18px;
  width: 18px;
  border: 2px solid black;
  border-radius: 50%;
  background-color: #fff;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
