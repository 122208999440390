.empty_cookie {
  position: fixed;
  z-index: 1001;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.88);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
}
.empty_cookie-btn {
  border: none;
  width: 94px;
  height: 40px;
  background: black;
  color: #ffffff;
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.01em;
  cursor: pointer;
}
.empty_cookie-btn:focus {
  border: none;
  outline: unset;
}
.empty_cookie-p {
  font-family: "HelveticaNeueCyr";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 0px;
  margin: 0 32px 0 0;
}
@media screen and (max-width: 768px) {
  .empty_cookie {
    padding: 6px 18px 6px 20px;
  }
  .empty_cookie-btn {
    width: 140px;
    height: 36px;
    padding: 8px;
  }
  .empty_cookie-p {
    font-size: 12px;
    line-height: 115%;
    margin-right: 21px;
  }
  .empty_cookie-p span {
    display: none;
  }
}
